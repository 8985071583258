

















































































































































































import { Vue, Component, Watch } from 'vue-class-decorator'
import { GlobalModule, SchedulesModule } from '@/store'
import { Payload } from 'vue/interfaces'

@Component({
})
export default class DaysOffComponent extends Vue {
  @GlobalModule.Getter('getLoading') loading!: boolean
  @SchedulesModule.Action('requestPageData') loadPageData!: () => Promise<void>
  @SchedulesModule.Action('requestDeleteDaysOff') deleteDaysOff!: (id: number) => Promise<void>
  @SchedulesModule.Getter('getUsers') users!: any
  @SchedulesModule.Action('saveDaysOff') pushDaysOff!: (daysOff: Payload) => Promise<void>
  @SchedulesModule.Getter('getDaysOff') daysOff!: Array<any>
  @SchedulesModule.Getter('getSettings') settings!: Array<any>

  public daysOffDialog: boolean
  public daysOffDialogTitle: string
  public daysOffDialogData: any
  public datePickerMenu: boolean
  public reasonsList: Array<string>

  constructor() {
    super()
    this.daysOffDialogTitle = 'Создание'
    this.daysOffDialog = false
    this.daysOffDialogData = { }
    this.datePickerMenu = false
    this.reasonsList = [
      'Больничный',
      'Отпуск',
      'Другая'
    ]
  }

  // Watch
  @Watch('daysOffDialogData.dates') checkDates() {
    if (this.daysOffDialogData.dates.length === 2 && this.daysOffDialogData.dates[0] >= this.daysOffDialogData.dates[1]) {
      this.daysOffDialogData.dates = [this.daysOffDialogData.dates[1], this.daysOffDialogData.dates[0]]
    }

    if (this.daysOffDialogData.dates.length === 2 && this.daysOffDialogData.dates[0] === this.daysOffDialogData.dates[1]) {
      this.daysOffDialogData.dates = [this.daysOffDialogData.dates[0]]
    }
  }

  get dialogDatesText() {
    return this.daysOffDialogData ? this.daysOffDialogData.dates.join(' ~ ') : ''
  }

  get dialogUserOwnDaysOff() {
    return this.maxDaysOffPerYear
    // if (!this.daysOffDialogData?.user_id) {
    //   return 21
    // }

    // const user = this.users.filter((user) => user.user_id === this.daysOffDialogData.user_id)
    // return user[0].days_off_max_count
  }

  get dialogUserMaxDaysOff() {
    if (!this.daysOffDialogData?.user_id) {
      return 21
    }

    const user = this.users.filter((user) => user.user_id === this.daysOffDialogData.user_id)
    return user[0].days_off_max_count
  }

  get maxDaysOffPerYear() {
    return this.settings.find((setting) => setting.title === 'days_off_per_year').content
  }

  getUserName(id) {
    const user = this.users.filter((user) => user.user_id === id)
    return user[0].title + ' (' + (this.maxDaysOffPerYear - user[0].days_off_max_count) + '/' + this.maxDaysOffPerYear + ')'
  }

  checkFields() {
    if (!this.daysOffDialogData.user_id || !this.daysOffDialogData.reason || this.daysOffDialogData.dates.length === 0) {
      return false
    } else {
      return true
    }
  }

  deleteProcess(id: number) {
    this.deleteDaysOff(id)
      .then(() => {
        this.$noty('success', 'Успешно удалено!', 7000).show()
        this.loadPageData()
      })
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  saveDaysOff() {
    if (!this.checkFields()) return this.$noty('error', 'Не все поля заполнены корректно', 5000).show()

    this.pushDaysOff(this.daysOffDialogData as Payload)
      .then(() => {
        this.$noty('success', 'Успешно сохранено!', 5000).show()
        this.daysOffDialog = false
        this.loadPageData()
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  createDaysOffDialog() {
    /* eslint-disable @typescript-eslint/camelcase */
    this.daysOffDialogTitle = 'Создание'
    this.daysOffDialogData = {
      user_id: this.users[0].user_id,
      reason: null,
      comment: '',
      dates: []
    }
    /* eslint-disable @typescript-eslint/camelcase */

    this.daysOffDialog = true
  }

  editDaysOff(id: number) {
    const oneDaysOff = this.daysOff.filter((oneDaysOff) => oneDaysOff.days_off_id === id)

    /* eslint-disable @typescript-eslint/camelcase */
    this.daysOffDialogTitle = 'Редактирование'
    this.daysOffDialogData = {
      days_off_id: oneDaysOff[0].days_off_id,
      user_id: oneDaysOff[0].user_id,
      reason: oneDaysOff[0].reason,
      comment: oneDaysOff[0].comment,
      dates: oneDaysOff[0].dates
    }

    this.daysOffDialog = true
    /* eslint-enable @typescript-eslint/camelcase */
  }
}
